import { GET_USER_INFO } from './actionTypes';

const defaultState = {
  userInfo: {}
}

export default (state=defaultState, action) => {
  switch(action.type){
    case GET_USER_INFO:
      return {
        ...state,
        userInfo: action.data
      }
    default: 
      return state;
  }
}
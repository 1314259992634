import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LoadingText } from '@containers/common/loading';

const AsyncLayout = Loadable({
  loader: () => import('@containers/layout/HomeLayout'),
  loading: LoadingText
})
const NewerLayout = Loadable({
  loader: () => import('@containers/layout/NewerLayout'),
  loading: LoadingText
})
const AsyncGuide = Loadable({
  loader: () => import('@views/home/newer/guide'),
  loading: LoadingText
})
const AsyncQuestion = Loadable({
  loader: () => import('@views/home/newer/question'),
  loading: LoadingText
})
const AsyncService = Loadable({
  loader: () => import('@views/home/newer/service'),
  loading: LoadingText
})
const AsyncCs = Loadable({
  loader: () => import('@views/home/newer/cs'),
  loading: LoadingText
})
const AsyncNoMatch = Loadable({
  loader: () => import('@containers/common/404'),
  loading: LoadingText
})
// 账户中心布局
const AboutRouter = (
  <AsyncLayout>
    <NewerLayout>
      <Switch>
        <Route path='/newer/guide' component={ AsyncGuide }/>
        <Route path='/newer/question' component={ AsyncQuestion }/>
        <Route path='/newer/service' component={ AsyncService }/>
        <Route path='/newer/cs' component={ AsyncCs }/>

        <Redirect from="/about" to="/about/index" />
        <Route component={ AsyncNoMatch } />
      </Switch>
    </NewerLayout>
  </AsyncLayout>
)

export default AboutRouter;
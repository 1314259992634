import { combineReducers } from 'redux';
import userInfoReducer from '@containers/layout/store/userInfoReducer';
import assetInfoReducer from '@containers/layout/store/assetInfoReducer';
import ticketInfoReducer from '@containers/layout/store/ticketInfoReducer';
import accountTypeReducer from '@containers/layout/store/accountTypeReducer';

const reducer = combineReducers({
  top: userInfoReducer,
  asset: assetInfoReducer,
  ticket: ticketInfoReducer,
  account: accountTypeReducer
});

export default reducer;
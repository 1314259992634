import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LoadingText } from '@containers/common/loading';

const AsyncLayout = Loadable({
  loader: () => import('@containers/layout/HomeLayout'),
  loading: LoadingText
})
const DisclosureLayout = Loadable({
  loader: () => import('@containers/layout/DisclosureLayout'),
  loading: LoadingText
})
const AsyncPrepare = Loadable({
  loader: () => import('@views/home/disclosure/prepare'),
  loading: LoadingText
})
const AsyncDisclosure = Loadable({
  loader: () => import('@views/home/disclosure/disclosure'),
  loading: LoadingText
})
const AsyncCustody = Loadable({
  loader: () => import('@views/home/disclosure/custody'),
  loading: LoadingText
})
const AsyncRisks= Loadable({
  loader: () => import('@views/home/disclosure/risks'),
  loading: LoadingText
})
const AsyncPartners= Loadable({
  loader: () => import('@views/home/disclosure/partners'),
  loading: LoadingText
})
const AsyncOrganize= Loadable({
  loader: () => import('@views/home/disclosure/organize'),
  loading: LoadingText
})
const AsyncBranch= Loadable({
  loader: () => import('@views/home/disclosure/branch'),
  loading: LoadingText
})
const AsyncChannel= Loadable({
  loader: () => import('@views/home/disclosure/channel'),
  loading: LoadingText
})
const AsyncAuditing= Loadable({
  loader: () => import('@views/home/disclosure/auditing'),
  loading: LoadingText
})
const AsyncAudit= Loadable({
  loader: () => import('@views/home/disclosure/audit'),
  loading: LoadingText
})
const AsyncCompliance= Loadable({
  loader: () => import('@views/home/disclosure/compliance'),
  loading: LoadingText
})
const AsyncCharge= Loadable({
  loader: () => import('@views/home/disclosure/charge'),
  loading: LoadingText
})
const AsyncOverdue= Loadable({
  loader: () => import('@views/home/disclosure/overdue'),
  loading: LoadingText
})
const AsyncOperate= Loadable({
  loader: () => import('@views/home/disclosure/operate'),
  loading: LoadingText
})
const AsyncBorrower= Loadable({
  loader: () => import('@views/home/disclosure/borrower'),
  loading: LoadingText
})
const AsyncBasic= Loadable({
  loader: () => import('@views/home/disclosure/basic'),
  loading: LoadingText
})

const AsyncEvaluate= Loadable({
  loader: () => import('@views/home/disclosure/evaluate'),
  loading: LoadingText
})

const AsyncCapital= Loadable({
  loader: () => import('@views/home/disclosure/capital'),
  loading: LoadingText
})
const AsyncMajor= Loadable({
  loader: () => import('@views/home/disclosure/major'),
  loading: LoadingText
})
const AsyncConsult= Loadable({
  loader: () => import('@views/home/disclosure/consult'),
  loading: LoadingText
})
const AsyncSystem= Loadable({
  loader: () => import('@views/home/disclosure/system'),
  loading: LoadingText
})
const AsyncSystemDetail1= Loadable({
  loader: () => import('@views/home/disclosure/system_detail1'),
  loading: LoadingText
})
const AsyncSystemDetail2= Loadable({
  loader: () => import('@views/home/disclosure/system_detail2'),
  loading: LoadingText
})
const AsyncSystemDetail3= Loadable({
  loader: () => import('@views/home/disclosure/system_detail3'),
  loading: LoadingText
})
const AsyncLoan= Loadable({
  loader: () => import('@views/home/disclosure/loan'),
  loading: LoadingText
})
const AsyncRisk= Loadable({
  loader: () => import('@views/home/disclosure/risk'),
  loading: LoadingText
})
const AsyncArticle = Loadable({
  loader: () => import('@views/home/disclosure/article'),
  loading: LoadingText
})
const AsyncArticleDetail = Loadable({
  loader: () => import('@views/home/disclosure/article_detail'),
  loading: LoadingText
})
const AsyncNoMatch = Loadable({
  loader: () => import('@containers/common/404'),
  loading: LoadingText
})
// 账户中心布局
const DisclosureRouter = (
      <AsyncLayout>
        <DisclosureLayout>
          <Switch>
            <Route exact path='/disclosure/index' component={ AsyncPrepare }/>
            <Route path='/disclosure/disclosure' component={ AsyncDisclosure }/>
            <Route path='/disclosure/custody' component={ AsyncCustody }/>
            <Route path='/disclosure/risks' component={ AsyncRisks }/>
            <Route path='/disclosure/partners' component={ AsyncPartners }/>
            <Route path='/disclosure/organize' component={ AsyncOrganize }/>
            <Route path='/disclosure/branch' component={ AsyncBranch }/>
            <Route path='/disclosure/channel' component={ AsyncChannel }/>
            <Route path='/disclosure/auditing' component={ AsyncAuditing }/>
            <Route path='/disclosure/audit' component={ AsyncAudit }/>
            <Route path='/disclosure/compliance' component={ AsyncCompliance }/>
            <Route path='/disclosure/charge' component={ AsyncCharge }/>
            <Route path='/disclosure/overdue' component={ AsyncOverdue }/>
            <Route path='/disclosure/operate' component={ AsyncOperate }/>
            <Route path='/disclosure/borrower' component={ AsyncBorrower }/>
            <Route path='/disclosure/basic' component={ AsyncBasic }/>
            <Route path='/disclosure/evaluate' component={ AsyncEvaluate }/>
            <Route path='/disclosure/capital' component={ AsyncCapital }/>
            <Route path='/disclosure/major' component={ AsyncMajor }/>
            <Route path='/disclosure/consult' component={ AsyncConsult }/>
            <Route path='/disclosure/system' component={ AsyncSystem }/>
            <Route path='/disclosure/system_detail1' component={ AsyncSystemDetail1 }/>
            <Route path='/disclosure/system_detail2' component={ AsyncSystemDetail2 }/>
            <Route path='/disclosure/system_detail3' component={ AsyncSystemDetail3 }/>
            <Route exact path='/disclosure/article/:type' component={ AsyncArticle }/>
            <Route path='/disclosure/article/:type/:id' component={ AsyncArticleDetail }/>
            <Route path='/disclosure/loan' component={ AsyncLoan }/>
            <Route path='/disclosure/risk' component={ AsyncRisk }/>
            <Redirect from="/disclosure" to="/disclosure/index" />
            <Route component={ AsyncNoMatch } />
          </Switch>
        </DisclosureLayout>
      </AsyncLayout>
)

export default DisclosureRouter;
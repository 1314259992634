import { GET_TICKET_INFO } from './actionTypes';

const defaultState = {
  ticketInfo: {
    type1: {count: 0},
    type3: {count: 0}
  }
}
export default (state=defaultState, action) => {
  switch(action.type){
    case GET_TICKET_INFO:
      return {
        ...state,
        ticketInfo: action.data
      }
    default: 
      return state;
  }
}
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LoadingText } from '@containers/common/loading';

const CallCenter = Loadable({
  loader: () => import('@views/work/callcenter'),
  loading: LoadingText
})

const WorkRouter = (
    <Switch>
      <Route path='/work/callcenter/call_user_record' component={ CallCenter }/>
    </Switch>
)
export default WorkRouter;
import axios from 'axios';
import {getToken, reqToken, setTokenValid} from './access';
import {Notify} from 'zent';


//创建实例
const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 15000,
})
//request 请求拦截器
service.interceptors.request.use((config) => {
  // if (config.loading !== false) {
  //   console.log('加载中', 10);
  // }
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }
  config.headers.sysv = navigator.userAgent;
  config.headers.appv = 'v1.0.1';
  config.headers.platform = 'pc';
  config.headers.semcode = 1;
  config.headers.token = getToken();
  return config;
}, (error) => {
  console.log(error);
  Promise.reject(error);
})
//request 响应拦截器
service.interceptors.response.use(
  response => {
    // if (response.config.loading !== false) {
    //   console.log('加载中', 10);
    // }
    return new Promise((resolve, reject) => {
      //接口返回处理
      switch (response.data.code) {
        case '900':
          Notify.error('该接口已停止服务');
          console.log('该接口已停止服务' + response.request.responseURL);
          reject(response.data);
          break
        case '901':
          Notify.error("客户端版本太低,当前版本无法使用该功能，请等待更新");
          console.log('客户端版本太低' + response.request.responseURL);
          reject(response.data);
          break
        case '902':
          Notify.error("浏览器版本太低,当前浏览器版本无法使用该功能，请升级或更换浏览器");
          console.log('浏览器版本太低' + response.request.responseURL);
          reject(response.data);
          break
        case '903':
          Notify.error('无权访问');
          console.log('无权访问该接口' + response.request.responseURL);
          reject(response.data);
          break
        case '904':
          Notify.error('资源不存在');
          console.log('资源不存在' + response.request.responseURL);
          reject(response.data);
          break
        case '905':
          Notify.error('系统异常，请稍后重试');
          console.log('系统异常，请通知后端！' + response.request.responseURL);
          reject(response.data);
          break
        case '906':
          Notify.error('系统繁忙，请稍后重试');
          console.log('系统繁忙，请稍后重试！' + response.request.responseURL);
          reject(response.data);
          break;
        case '907':
          setTokenValid(null) //清除本地登录状态
          window.location.reload();
          reject(response.data)
          break;
        case '908':
          Notify.error('系统繁忙，请稍后重试')
          console.log('接口请求错误，请调整客户端代码' + response.request.responseURL)
          reject(response.data)
          break;
        case '999':
          setTokenValid(null)
          reqToken().then(() => {
            doLogin();
          }).catch(() => {
            Notify.error('系统繁忙，请稍后重试');
            reject(response.data);
          })
          break;
        case '000':
          resolve(response.data);
          break;
        default:
          reject(response.data);
      }
    })

  },
  error => {
    Notify.error('服务端错误', 2000);
    console.log('服务端错误' + error);
    return Promise.reject(error)
  }
)

// 跳转登录
const doLogin = () => {
  window.location.href = '/login?redirect=' + encodeURIComponent(window.location.pathname)
}
export default service

import Cookies from 'js-cookie';
import request from "./request";

const TokenKey = process.env.REACT_APP_SESS_ID;

//获取本地存储的token
export function getToken() {
  return Cookies.get(TokenKey);
}

//设置本地token
export function setToken(token) {
  if (token === null) {
    Cookies.remove(TokenKey, {domain: '.jylc168.com'});
    return Cookies.remove(TokenKey)
  }
  return Cookies.set(TokenKey, token);
}

export function setTokenValid(value) {
  if (value === null) {
    Cookies.remove('TokenValid', {domain: '.jylc168.com'});
    return Cookies.remove('TokenValid');
  }
  return Cookies.set('TokenValid', true);
}

//生成token
export function reqToken() {
  return new Promise((resolve, reject) => {
    request({
      url: '/v1/access/token',
      method: 'post',
    }).then(res => {
      setToken(res.data.token);
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

//是否登录
export function isLogin() {
  return (getToken() && Cookies.get('TokenValid'));
}

import React, {Component, Fragment} from 'react';
import {Provider} from 'react-redux';
import store from '@store/configStore';
import RouterIndex from '@router';
import {reqToken} from '@utils/access';
import 'zent/css/index.css';
import 'element-theme-default';
import '@assets/common/common.scss';

class App extends Component {
  constructor(props) {
    super(props);
    let UA = navigator.userAgent;
    if (UA.match(/Android/i) || (UA.indexOf('iPhone') !== -1) || (UA.indexOf('iPod') !== -1)) {
      window.location = 'https://m.jylc168.com';
    }
    this.state = {
      tokenAlive: false
    }

    // 初始化
    reqToken().then(() => {
      this.setState({
        tokenAlive: true
      })
    });
  }

  render() {
    return (
      <Fragment>
        {
          this.state.tokenAlive ?
            <Provider store={store}>
              <RouterIndex/>
            </Provider>
            : ''
        }
      </Fragment>
    );
  }
}

export default App;

import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LoadingText } from '@containers/common/loading';
import HomeWrapper from '@containers/layout/HomeWrapper';

const AsyncHome = Loadable({
  loader: () => import('@views/home'),
  loading: LoadingText
})
const AsyncLogin = Loadable({
  loader: () => import('@views/home/login'),
  loading: LoadingText
})
const AsyncSetPwd = Loadable({
  loader: () => import('@views/home/setpwd'),
  loading: LoadingText
})
const AsyncReg = Loadable({
  loader: () => import('@views/home/reg'),
  loading: LoadingText
})
const AsyncCalc = Loadable({
  loader: () => import('@views/home/calc'),
  loading: LoadingText
})

const AsyncProduct = Loadable({
  loader: () => import('@views/home/product/product'),
  loading: LoadingText
})
const AsyncDetail = Loadable({
  loader: () => import('@views/home/product/detail'),
  loading: LoadingText
})
const AsyncIntroduction = Loadable({
  loader: () => import('@views/home/product/introduction'),
  loading: LoadingText
})
const AsyncItemone = Loadable({
  loader: () => import('@views/home/product/itemone'),
  loading: LoadingText
})



const AsyncProtocolReg = Loadable({
  loader: () => import('@views/home/protocol/register'),
  loading: LoadingText
})
const AsyncProtocolInvest = Loadable({
  loader: () => import('@views/home/protocol/invest'),
  loading: LoadingText
})
const AsyncProtocolHr = Loadable({
  loader: () => import('@views/home/protocol/hr'),
  loading: LoadingText
})
const AsyncNoMatch = Loadable({
  loader: () => import('@containers/common/404'),
  loading: LoadingText
})

const AsyncMove = Loadable({
  loader: () => import('@views/home/fullest/move'),
  loading: LoadingText
})

const AsyncSeo = Loadable({
  loader: () => import('@views/home/access/seo_reg'),
  loading: LoadingText
})

const HomeRouter = () => (
  <Switch>
    <Route exact path='/' component={HomeWrapper(AsyncLogin)} />
    <Route path='/login' component={HomeWrapper(AsyncLogin)} />
    {/* <Route path='/setpwd' component={HomeWrapper(AsyncSetPwd)} />
    <Route path='/access/reg' component={HomeWrapper(AsyncReg)} />
    <Route exact path='/product' component={HomeWrapper(AsyncProduct)} />
    <Route path='/product/:id' component={HomeWrapper(AsyncDetail)} />
    <Route path='/introduction' component={HomeWrapper(AsyncIntroduction)} />
    <Route path='/itemone' component={HomeWrapper(AsyncItemone)} />
    <Route path='/calc' component={HomeWrapper(AsyncCalc)} />

    <Route path='/protocol/register' component={HomeWrapper(AsyncProtocolReg)} />
    <Route path='/protocol/invest' component={HomeWrapper(AsyncProtocolInvest)} />
    <Route path='/protocol/hr' component={HomeWrapper(AsyncProtocolHr)} />
    <Route path='/fullest/move' component={AsyncMove} />
    <Route path='/access/seo_reg' component={AsyncSeo} /> */}
    <Redirect exact from="/index.html" to="/" />
    <Route component={AsyncNoMatch} />
  </Switch>
)
export default HomeRouter;
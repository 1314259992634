import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LoadingText } from '@containers/common/loading';

const EventLayout = Loadable({
  loader: () => import('@containers/layout/EventLayout'),
  loading: LoadingText
})

const EventSignature = Loadable({
  loader: () => import('@views/event/hd2019/signature'),
  loading: LoadingText
})
const EventDlqx = Loadable({
  loader: () => import('@views/event/hd2019/dlqx'),
  loading: LoadingText
})
const EventSign = Loadable({
  loader: () => import('@views/event/hd2019/sign'),
  loading: LoadingText
})
const EventCriminal = Loadable({
  loader: () => import('@views/event/hd2019/criminal'),
  loading: LoadingText
})

const EventIntell = Loadable({
  loader: () => import('@views/event/hd2019/intell'),
  loading: LoadingText
})

const EventVoyage = Loadable({
  loader: () => import('@views/event/hd2019/voyage'),
  loading: LoadingText
})


const EventRouter = (
  <EventLayout>
    <Switch>
      <Route exact path='/event/signature' component={ EventSignature }/>
      <Route exact path='/event/dlqx' component={ EventDlqx }/>
      <Route exact path='/event/sign' component={ EventSign }/>
      <Route exact path='/event/criminal' component={ EventCriminal }/>
      <Route exact path='/event/intell' component={ EventIntell }/>
      <Route exact path='/event/voyage' component={ EventVoyage }/>
    </Switch>
  </EventLayout>
)
export default EventRouter;
import { GET_USER_type } from './actionTypes';
let shopDetailInfo = sessionStorage.getItem('shopDetailInfo')?JSON.parse(sessionStorage.getItem('shopDetailInfo')):{};
let accountType = sessionStorage.getItem('accountType')?sessionStorage.getItem('accountType'):'';

const defaultState = {
  shopDetailInfo,
  accountType
}

export default (state=defaultState, action) => {
  switch(action.type){
    case GET_USER_type:
        sessionStorage.setItem('shopDetailInfo', JSON.stringify(action.data));
      return {
        ...state,
        shopDetailInfo: action.data
      }
    default: 
      return state;
  }
}
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LoadingText } from '@containers/common/loading';

const AsyncLayout = Loadable({
  loader: () => import('@containers/layout/HomeLayout'),
  loading: LoadingText
})
// about
const AboutLayout = Loadable({
  loader: () => import('@containers/layout/AboutLayout'),
  loading: LoadingText
})
const AsyncAbout = Loadable({
  loader: () => import('@views/home/about'),
  loading: LoadingText
})
const AsyncArticle = Loadable({
  loader: () => import('@views/home/about/article'),
  loading: LoadingText
})
const AsyncArticleDetail = Loadable({
  loader: () => import('@views/home/about/article_detail'),
  loading: LoadingText
})
const AsyncHonor = Loadable({
  loader: () => import('@views/home/about/honor'),
  loading: LoadingText
})

const AsyncContact = Loadable({
  loader: () => import('@views/home/about/contact'),
  loading: LoadingText
})
const AsyncNoMatch = Loadable({
  loader: () => import('@containers/common/404'),
  loading: LoadingText
})
// 账户中心布局
const AboutRouter = (
    <AsyncLayout>
      <AboutLayout>
        <Switch>
          <Route exact path='/about/index' component={ AsyncAbout }/>
          <Route path='/about/news' component={ AsyncHonor }/>
          <Route path='/about/honor' component={ AsyncHonor }/>
          <Route path='/about/contact' component={ AsyncContact }/>
          <Route exact path='/about/article/:type' component={ AsyncArticle }/>
          <Route path='/about/article/:type/:id' component={ AsyncArticleDetail }/>

          <Redirect from="/about" to="/about/index" />
          <Route component={ AsyncNoMatch } />
        </Switch>
      </AboutLayout>
    </AsyncLayout>
)

export default AboutRouter;
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import { LoadingText } from '@containers/common/loading';

// accountloan 借款账户
const AccountLoanLayout = Loadable({
  loader: () => import('@containers/layout/AccountLoanLayout'),
  loading: LoadingText
})

const AsyncAccountloan = Loadable({
  loader: () => import('@views/accountloan'),
  loading: LoadingText
})

const AsyncAssets = Loadable({
  loader: () => import('@views/accountloan/assets'),
  loading: LoadingText
})

const AsyncManage = Loadable({
  loader: () => import('@views/accountloan/manage'),
  loading: LoadingText
})
const AsyncMessage = Loadable({
  loader: () => import('@views/accountloan/message'),
  loading: LoadingText
})
const AsyncRecharge = Loadable({
  loader: () => import('@views/accountloan/recharge'),
  loading: LoadingText
})
const AsyncWithdrawal = Loadable({
  loader: () => import('@views/accountloan/withdrawal'),
  loading: LoadingText
})
const AsyncBankcard = Loadable({
  loader: () => import('@views/accountloan/manage_bankcard'),
  loading: LoadingText
})
const AsyncReturnList = Loadable({
  loader: () => import('@views/accountloan/returnList'),
  loading: LoadingText
})

const AsyncNoMatch = Loadable({
  loader: () => import('@containers/common/404'),
  loading: LoadingText
})
// 账户中心布局
const AccountRouter = (
    <AccountLoanLayout>
      <Switch>
        <Route exact path='/accountloan/' component={ AsyncAccountloan }/>
        <Route exact path='/accountloan/assets' component={ AsyncAssets }/>
        <Route exact path='/accountloan/manage' component={ AsyncManage }/>
        <Route exact path='/accountloan/message' component={ AsyncMessage }/>
        <Route exact path='/accountloan/recharge' component={ AsyncRecharge }/>
        <Route exact path='/accountloan/withdrawal' component={ AsyncWithdrawal }/>
        <Route exact path='/accountloan/bankcard' component={ AsyncBankcard }/>
        <Route exact path='/accountloan/return_list/:date' component={ AsyncReturnList }/>

        <Redirect from="/accountloan/index" to="/accountloan" />
        <Route component={ AsyncNoMatch } />
      </Switch>
    </AccountLoanLayout>
)

export default AccountRouter;
import React, { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import ScrollToTop from '@containers/common/scrollToTop';
import history from '@utils/history';
import HomeRouter from './home';
import AccountRouter from './account';
import AccountLoanRouter from './accountloan';
import AboutRouter from './about';
import NewerRouter from './newer';
import DisclosureRouter from './disclosure';
import EventRouter from './event';
import MallRouter from './mall';
import WorkRouter from './work';

export default class extends Component {
  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
        <Switch>
          {/**账户中心**/}
          <Route path='/account' render = { props => AccountRouter }/>
          {/**借款账户**/}
          <Route path='/accountloan' render = { props => AccountLoanRouter }/>
          {/**关于我们**/}
          <Route path='/about' render = { props => AboutRouter }/>
          {/**新手指引**/}
          <Route path='/newer' render = { props => NewerRouter }/>
          {/**信息披露**/}
          <Route path='/disclosure' render = { props => DisclosureRouter }/>
          {/**金币商城**/}
          <Route path='/mall' render = { props => MallRouter }/>
          {/**活动**/}
          <Route path='/event' render = { props => EventRouter }/>
          {/**呼叫中心**/}
          <Route path='/work' render = { props => WorkRouter }/>
          <Redirect from="/event/index" to="/event" />
          {/**home**/}
          <HomeRouter />
        </Switch>
        </ScrollToTop>
      </Router>
    )
  }
}

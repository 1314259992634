import { GET_ASSET_INFO } from './actionTypes';

const defaultState = {
  assetInfo: {}
}

export default (state=defaultState, action) => {
  switch(action.type){
    case GET_ASSET_INFO:
      return {
        ...state,
        assetInfo: action.data
      }
    default: 
      return state;
  }
}